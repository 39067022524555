/* Split Screen CSS */
/* https://github.com/nathancahill/split */

.split {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.gutter {
  background-color: #eee;
  background-position: 50%;
  background-repeat: no-repeat;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}
